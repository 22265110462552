<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn  }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc" v-html="thematicDesc">
      </div>
      <div class="section_content">
        <div class="cards_panel">
          <div class="card_line" v-for="(lItme,lIdx) in cardInfos" :key="lIdx">
            <div class="card_item" v-for="(cItem,cIdx) in lItme" :key="cIdx">
              <div class="card_title">
                {{cItem.label}}
              </div>
              <div class="card_desc">
                <div class="item_line" v-for="(dItem,dIdx) in cItem.details" :key="dIdx">
                  <div class="item_line_icon"></div>
                  <div class="item_line_value">
                    <span :class="[{
                      'has_child':dItem.infos.length
                    }]" v-html="dItem.name"></span>
                    <div class="item_line_value_detail" v-for="(idItem,idIdx) in dItem.infos" :key="idIdx">
                      <div class="item_line_value_detail_icon"></div>
                      <div class="item_line_value_detail_value" v-html="idItem">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "thematicDesc":"",
      "cardInfos":[],
      "titleZh":"",
      "titleEn":"",
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeFeatures&secondaryTitle=One_stopPlatformforRadiopharmaceuticals`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.cardInfos = configData.cardInfos;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
        }else{
          _this.$notify({
            title: '失败',
            message: "放射性药物一体化平台数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">

.item_line{
  display: flex;
  align-items: flex-start;
  .has_child{
    // font-weight: bold;
  }
  .item_line_value_detail{
    display: flex;
    align-items: flex-start;
    .item_line_value_detail_icon{
      border-left: 8px solid #0086D1;
      border-top: 6px solid transparent;
      border-bottom:  6px solid transparent;
      border-right:  8px solid transparent;
      margin-top: 6px;
    }
    .item_line_value_detail_value{
      flex: 1;
      overflow: hidden;
      font-family: PingFang SC, PingFang SC;
      // font-weight: 400;
      // font-size: 14px;
      color: #666666;
      // line-height: 22px;
      font-weight: 400;
      font-size: 18px;
      // color: #333333;
      line-height: 35px;
    }
  }
  .item_line_icon{
    width: 15px;
    height: 15px;
    background: url("./images/icon@checked.png") 0 0 no-repeat;
    background-size: 100% auto;
    background-position: center left;
    margin: 10px 7px 10px 0;
  }
  .item_line_value{
    flex: 1;
    overflow: hidden;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 35px;
  }
}
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 63px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 43px;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 55px;
    }
    .section_content{
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #000000;
        height: 35px;
        line-height: 35px;
        padding-left: 57px;
        box-sizing: border-box;
        margin-bottom: 28px;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
      }
      .cards_panel{
        .card_line{
          display: flex;
          margin-bottom: 38px;
          .card_item{
            flex: 665;
            overflow: hidden;
            margin-right: 37px;
            padding: 12px 15px 34px 35px;
            box-sizing: border-box;
            background: #F6F7F9;
            // &:last-child{
            //   margin-right: 0;
            // }
            // &:nth-child(even){
            //   flex: 729;
            //   .card_desc{
            //     display: flex;
            //   }
            // }
            .card_title{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 24px;
              color: #0086D1;
              line-height: 53px;
              margin-bottom: 8px;
            }
            .card_desc{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
              line-height: 35px;
            }
          }
        }
        
      }
    }
  }
}
</style>