<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn   }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc" v-html="thematicDesc">
      </div>
      <div class="section_content">
        <div class="cards_panel">
          <div class="card_line" v-for="(lItme,lIdx) in cardInfos" :key="lIdx">
            <div class="card_item" v-for="(cItem,cIdx) in lItme" :key="cIdx">
              <div class="card_title">
                {{cItem.label}}
              </div>
              <div class="card_desc">
                <div class="item_line" v-for="(dItem,dIdx) in cItem.details" :key="dIdx">
                  <div class="item_line_icon"></div>
                  <div class="item_line_value">
                    <span :class="[{
                      'has_child':dItem.infos.length
                    }]" v-html="dItem.name"></span>
                    <div class="item_line_value_detail" v-for="(idItem,idIdx) in dItem.infos" :key="idIdx">
                      <div class="item_line_value_detail_icon"></div>
                      <div class="item_line_value_detail_value" v-html="idItem">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "thematicDesc":"",
      "cardInfos":[],
      "titleZh":"放射性药物一站式服务平台",
      "titleEn":"ONE-STOP PLATFORM FOR RADIOPHARMACEUTICALS",
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeFeatures&secondaryTitle=One_stopPlatformforRadiopharmaceuticals`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.cardInfos = configData.cardInfos;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
        }else{
          _this.$notify({
            title: '失败',
            message: "放射性药物一体化平台数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">

.item_line{
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  .has_child{
    // font-weight: bold;
  }
  .item_line_value_detail{
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.2rem;
    .item_line_value_detail_icon{
      border-left: 0.4rem solid #0086D1;
      border-top: 0.2rem solid transparent;
      border-bottom:  0.2rem solid transparent;
      border-right:  0.4rem  solid transparent;
      margin-top: 0.4rem;
    }
    .item_line_value_detail_value{
      flex: 1;
      overflow: hidden;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #666666;
      line-height: 1.4rem;
      margin-bottom: 0.2rem;
    }
  }
  .item_line_icon{
    width: 1rem;
    height: 1rem;
    background: url("./images/icon@checked.png") 0 0 no-repeat;
    background-size: 100% auto;
    background-position: center left;
    margin-right: 0.5rem;
  }
  .item_line_value{
    flex: 1;
    overflow: hidden;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 1rem;
    color: #333333;
    line-height: 1.4rem;
  }
}
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 1.5rem;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.4rem;
      margin-bottom: 2rem;
    }
    .section_content{
      .cards_panel{
        .card_line{
          display: flex;
          flex-direction: column;
          .card_item{
            flex: 665;
            overflow: hidden;
            padding: 1rem 0.5rem;
            box-sizing: border-box;
            background: #F6F7F9;
            margin-bottom: 1rem;
            .card_title{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 1.2rem;
              color: #0086D1;
              line-height: 1.4rem;
              margin-bottom: 0.5rem;
            }
            .card_desc{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 1rem;
              color: #333333;
              line-height: 1.4rem;
            }
          }
        }
        
      }
    }
  }
}
</style>